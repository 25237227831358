a {
  text-decoration: none;
}

.mt {
  margin-top: 1.2rem;
}
.mt-2 {
  margin-top: 2.4rem;
}

.mrAuto {
  margin-right: auto !important;
}

.image {
  padding: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.43);
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .marginTopSmall {
    margin-top: 20px;
  }
}

bold,
.bold {
  font-weight: bold;
}
.textRight {
  text-align: right;
}

.listStyleNone {
  list-style: none;
  padding-inline-start: 0px;
}
